import React, { FC } from 'react';
import {
  PageLayout,
  PageHeading,
  ParagraphWithGradientTop,
  MediaAppearance,
} from 'components';
import { graphql } from 'gatsby';
import { SeoType, MediaAppearanceTypes } from 'types';

interface MediaHubType {
  data: {
    strapiMediaHub: {
      title: string;
      pageDescription: string;
      seo: SeoType;
    };
    mediaAppearances: {
      edges: {
        node: MediaAppearanceTypes;
      }[];
    };
  };
}

const MediaHub: FC<MediaHubType> = ({ data }: any) => {
  const {
    strapiMediaHub: { title, pageDescription, seo, banner },
    mediaAppearances,
    strapiLearningHubPage,
  } = data;
  const { learningBanner } = strapiLearningHubPage;
  return (
    <PageLayout
      title="MediaHub"
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      hasContainerClass
      bannerTitle={banner?.title || learningBanner.title}
      bannerTagline={banner?.tagline || learningBanner.tagline}
      bannerImage={banner?.image || learningBanner.image}
      bannerButton={banner?.ctaButton || learningBanner.ctaButton}
      bannerPlaceholder={
        banner?.postcodePlaceholder || learningBanner.postcodePlaceholder
      }
    >
      <PageHeading title={title} level={1} />
      <ParagraphWithGradientTop>{pageDescription}</ParagraphWithGradientTop>
      <section className="mediahub">
        {mediaAppearances.edges.map((media: any) => {
          return <MediaAppearance Media={media.node} key={media.node.id} />;
        })}
      </section>
    </PageLayout>
  );
};

export default MediaHub;
export const query = graphql`
  query MediaHub {
    strapiMediaHub(id: {}) {
      slug
      title
      pageDescription
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      logos {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    strapiLearningHubPage {
      learningBanner: banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    mediaAppearances: allStrapiMediaAppearance(
      sort: { fields: strapiId, order: DESC }
    ) {
      edges {
        node {
          id
          content
          mediaLink
          title
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600, height: 400)
              }
            }
          }
        }
      }
    }
  }
`;
